import request from '@/util/request'
export function homeBanner(data) {
    return request({
      url: '/home/banner',
      method: 'post',
      data: data
    })
  }
  export function homeResource(data) {
    return request({
      url: '/home/resource',
      method: 'post',
      data: data
    })
  }

  export function sourceChannel(data) {
    return request({
      url: '/source/channel',
      method: 'post',
      data: data
    })
  }

  export function sourceCompany(data) {
    return request({
      url: '/source/company',
      method: 'post',
      data: data
    })
  }

  export function joinAdd(data) {
    return request({
      url: '/about/join-add',
      method: 'post',
      data: data
    })
  }

  export function sourceBusiness(data) {
    return request({
      url: '/source/business',
      method: 'post',
      data: data
    })
  }

  export default {
    homeBanner, //首页banner图
    homeResource, //动态资源
    sourceChannel, //合作伙伴
    joinAdd,
    sourceCompany, //公司简介
    sourceBusiness, //业务介绍
  }