import Vue from 'vue'
import axios from 'axios';
// import NProgress from 'nprogress'
// import 'nprogress/nprogress.css'

// 创建axios实例
const service = axios.create({
  baseURL: 'http://admin.sinkglobal.co.jp/', // api的base_url
  // baseURL: '/api/ api的base_url//
  timeout: 15000, // 请求超时时间
  contentType: 'application/x-www-form-urlencoded',
  XRequestedWith : 'XMLHttpRequest',
})
service.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';
// request拦截器
service.interceptors.request.use(config => {
// NProgress.start()
  return config;
}, error => {
  Promise.reject(error);
});

// respone拦截器
service.interceptors.response.use(
  response => {
    const res = response.data;
    // NProgress.done()
    return response.data
  },
  error => {
    return Promise.reject(error)
  }
)

export default service;